import { useDispatch } from 'react-redux';

import { AccountInfo, SilentRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import { persistor } from 'app/store';
import { handleTravelAgentToken, isSSOSession, isTravelAgentPortal } from 'auth/auth';
import { loginRequest, msalConfig } from 'auth/msalConfig';
import appConfig from 'config/appConfig';
import { deleteSSOSessionFromStorage, deleteTokenFromStorage, getTokenFromStorage } from 'config/localStorage';
import { setUsername } from 'slices/userDataSlice';

const refreshBufferInMS = 1000 * 60 * 5; // 5 minutes

export function useAuthWrapperHelpers() {
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const isAgentPortal = isTravelAgentPortal();
  const isCustomAuth = isSSOSession();

  async function requestMsalToken(): Promise<string | undefined> {
    const idTokenClaims: any = accounts && accounts[0] && accounts[0].idTokenClaims;
    let idToken;
    let refreshCustomToken = false;
    let refreshToken = false;

    dispatch(setUsername(accounts[0]?.name));

    if (idTokenClaims && idTokenClaims.exp) {
      const expCheck = new Date(idTokenClaims.exp * 1000 - refreshBufferInMS);
      if (expCheck < new Date()) {
        refreshToken = true;
      }
    }

    const request: SilentRequest = {
      ...loginRequest,
      account: accounts[0] as AccountInfo,
      forceRefresh: refreshToken,
    };

    try {
      const silentResponse = await instance.acquireTokenSilent(request);
      idToken = silentResponse.idToken;

      // Update token expiration time
      const updatedAccounts = instance.getAllAccounts();
      if (updatedAccounts.length > 0) {
        const updatedAccount = updatedAccounts.find((acc) => acc.username === accounts[0]?.username);
        if (updatedAccount) {
          idTokenClaims.exp = updatedAccount.idTokenClaims?.exp;
        }
      }
    } catch (e) {
      try {
        const response = await instance.acquireTokenPopup(request);
        idToken = response.idToken;

        // Update token expiration time
        const updatedAccounts = instance.getAllAccounts();
        if (updatedAccounts.length > 0) {
          const updatedAccount = updatedAccounts.find((acc) => acc.username === accounts[0]?.username);
          if (updatedAccount) {
            idTokenClaims.exp = updatedAccount.idTokenClaims?.exp;
          }
        }
        refreshCustomToken = true;
      } catch (e) {
        console.error('Popup token acquisition failed, falling back to redirect.', e);
        try {
          await instance.acquireTokenRedirect({ ...request, redirectUri: msalConfig.auth.redirectUri });
        } catch (redirectError) {
          console.error('Redirect token acquisition failed.', redirectError);
          throw redirectError;
        }
      }
    }

    if (isAgentPortal && idToken) {
      return await handleTravelAgentToken(idToken, refreshCustomToken);
    } else {
      return idToken;
    }
  }

  async function logout() {
    if (!isCustomAuth) {
      instance.logout();
    } else {
      window.location.replace(appConfig.agentPortalSSORedirectUrl || '/');
    }

    await persistor.purge().then(() => {
      deleteTokenFromStorage();
      deleteSSOSessionFromStorage();
    });
  }

  async function requestToken(): Promise<string | undefined> {
    if (isCustomAuth) {
      return getTokenFromStorage();
    } else {
      return await requestMsalToken();
    }
  }

  return {
    requestToken,
    logout,
  };
}
